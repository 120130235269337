import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "../../../components/__common/Typography/Typography";
import "./Consignment.scss";
import { useLocation } from "react-router";
import { ROUTES } from "../../../configs/routes";

const Consignment = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    const main = document.getElementById("main-body");
    if (main) {
      if (
        pathname === ROUTES.CONSIGNMENT ||
        pathname === ROUTES.OLD_CONSIGNMENT
      ) {
        main.style.overflow = "hidden";
      } else {
        main.style.overflow = "auto";
      }
    }
  }, [pathname]);
  return (
    <div className="consignment" data-testid="consignment">
      <Typography variant="body1" className="consignment__date">
        {t("PRIVACY_BDP_CONSIGNMENT_EFFECTIVE_DATE")}
      </Typography>
      <div>
        <table cellSpacing="0">
          <tbody>
            <tr>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_COMPANY_KR")}
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_COMPANY")}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_SAMSUNG_KR")}
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_SAMSUNG")}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" weight="w400">
                  <Trans
                    i18nKey="PRIVACY_BDP_CONSIGNMENT_GOOGLE_KR"
                    components={{
                      a: (
                        // eslint-disable-next-line
                        <a href="https://cloud.google.com/terms/subprocessors" />
                      ),
                    }}
                  />
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  <Trans
                    i18nKey="PRIVACY_BDP_CONSIGNMENT_GOOGLE"
                    components={{
                      a: (
                        // eslint-disable-next-line
                        <a href="https://cloud.google.com/terms/subprocessors" />
                      ),
                    }}
                  />
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Consignment;
