import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import "./Header.scss";
import { ROUTES } from "../../configs/routes";
import { Svg, SVG_ICONS } from "../__common/Svg/Svg";
import { Overlay } from "../__common/Overlay/Overlay";
import { SubNav } from "./SubNav";
import { Container } from "../Grid/Grid";
import { HeaderMenu } from "../../constants/nav";
import { useWindowSize } from "../../hooks/window-hooks";
import { HeaderMobile } from "./HeaderMobile";
import { Image } from "../__common/Image";
import { Typography } from "../__common/Typography/Typography";
import { LinkButton } from "../__common/_controls";
import { useUserHooks } from "../../hooks/user-hooks";
import { LOGIN_ID_TYPE, User } from "../../types/user";
import { UtilsUser } from "../../utils/UtilsUser";
import { useAuth } from "../../hooks/auth-hooks";
import { API_ENDPOINTS, API_SA_BASE_URL } from "../../constants/api";
import { useTranslation } from "react-i18next";
import triggerGAEvent from "../../utils/UtilsTriggerGAEvent";

import {
  EVENT_CATEGORY_HEADER,
  EVENT_HEADER_ACTION_PRIVACY_LOGO,
} from "../../constants/analytics";
import { useHub } from "../../hooks/hub-hooks";

export const Header: React.FC = () => {
  const { isMobile } = useWindowSize();

  return !isMobile ? <HeaderDesktop /> : <HeaderMobile />;
};

export const HeaderDesktop: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState<string | number>("");
  const [showSubMenu, setShowSubMenu] = useState(false);
  const { pathname } = location;
  const { userData } = useUserHooks();
  const {
    hasLocalSite,
    isEuropeCountry,
    handleLocalSiteRedirect,
    handleGAMyDataRedirectNav,
  } = useHub();

  const hasLocalSiteAndNotEuCountry = hasLocalSite && !isEuropeCountry;

  const handleNavOnClick = (id: string | number, gaAction: string) => {
    setActiveMenu(id);
    setIsProfileOpen(false);

    if (gaAction !== "") {
      if (id === 2) {
        handleGAMyDataRedirectNav();
      } else {
        triggerGAEvent(EVENT_CATEGORY_HEADER, gaAction);
      }
    }
  };

  const handleNavOnMouseLeave = () => {
    setIsProfileOpen(false);
  };

  const handleOnProfileClick = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  useEffect(() => {
    setIsProfileOpen(false);
  }, [location]);

  return (
    <>
      <Overlay
        open={showSubMenu || isProfileOpen}
        onClick={handleOnProfileClick}
      />
      <header
        className={classNames("header", {
          underlined: pathname !== ROUTES.ROOT,
        })}
        id="header"
        tabIndex={-1}
      >
        <Container className="header__content">
          <div className="header__logo">
            <div className="header__logo__container">
              <Link
                to={ROUTES.ROOT}
                onClick={() =>
                  handleNavOnClick("", EVENT_HEADER_ACTION_PRIVACY_LOGO)
                }
              >
                <Svg icon={SVG_ICONS.LOGO} width={206} height={26} />
              </Link>
            </div>
          </div>
          <div className="header__nav">
            {HeaderMenu.map((menu) => (
              <div
                key={menu.id}
                className={classNames("nav-item", {
                  active: activeMenu === menu.id,
                })}
                onMouseLeave={menu.items && handleNavOnMouseLeave}
              >
                {menu.title.link !== "" ? (
                  menu.id === 2 && hasLocalSiteAndNotEuCountry ? (
                    <Typography
                      className={classNames("nav-item-text", {
                        active: activeMenu === menu.id,
                      })}
                      onClick={() => {
                        handleGAMyDataRedirectNav();
                        handleLocalSiteRedirect();
                      }}
                      variant="body2"
                    >
                      <Link to="#">{t(menu.title.text)}</Link>
                    </Typography>
                  ) : (
                    <NavLink
                      className={classNames("nav-item-text", {
                        active: activeMenu === menu.id,
                      })}
                      to={menu.title.link}
                      activeClassName="active"
                      onClick={() => handleNavOnClick(menu.id, menu.gaAction)}
                    >
                      {t(menu.title.text)}
                    </NavLink>
                  )
                ) : (
                  <Typography
                    className={classNames("nav-item-text", {
                      active: activeMenu === menu.id,
                    })}
                    onClick={() => {
                      setShowSubMenu(true);
                    }}
                    variant="body2"
                  >
                    <Link to="#">{t(menu.title.text)}</Link>
                  </Typography>
                )}
                {menu.items && (
                  <SubNav
                    isActive={showSubMenu}
                    navigation={menu}
                    setShowSubMenu={setShowSubMenu}
                    setActiveMenu={setActiveMenu}
                  />
                )}
              </div>
            ))}
            {
              <Profile
                userData={userData}
                onProfileClick={handleOnProfileClick}
                isProfileOpen={isProfileOpen}
              />
            }
          </div>
        </Container>
      </header>
    </>
  );
};

interface ProfileProps {
  userData: User;
  isProfileOpen?: boolean;
  onProfileClick?: () => void;
}

const Profile: React.FC<ProfileProps> = (props) => {
  const { logout, login } = useAuth();
  const { userData } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div className="profile">
      {!UtilsUser.isUserLoggedIn() && (
        <Link to="#" onClick={() => login(pathname)}>
          <Svg icon={SVG_ICONS.PROFILE_SIGNOUT} width={40} height={40} />
        </Link>
      )}

      {UtilsUser.isUserLoggedIn() && (
        <>
          <button onClick={props.onProfileClick}>
            {userData.profileImageUrl ? (
              <Image
                image={userData.profileImageUrl}
                name={userData.name}
                width={40}
                height={40}
              />
            ) : (
              <Svg
                icon={SVG_ICONS.PROFILE}
                className="profile-placeholder"
                width={40}
                height={40}
              />
            )}
          </button>
          <div
            className={classNames("profile__popup", {
              open: props.isProfileOpen,
            })}
          >
            <div className="profile__popup__user">
              <div className="profile__popup__user__icon">
                {userData.profileImageUrl ? (
                  <Image
                    image={userData.profileImageUrl}
                    name={userData.name}
                    width={48}
                    height={48}
                  />
                ) : (
                  <Svg
                    icon={SVG_ICONS.PROFILE}
                    className="profile-placeholder"
                    width={48}
                    height={48}
                  />
                )}
              </div>
              <div>
                <Typography
                  className="profile__popup__user__name"
                  variant="title3"
                >
                  {userData.name}
                </Typography>
              </div>
            </div>
            <div className="profile__popup__message">
              <Typography variant="body1">
                {t("PRIVACY_HEADER_USER_POPUP_GREETING")} <br />{" "}
                {t("PRIVACY_HEADER_USER_POPUP_GREETING_TEXT")}
              </Typography>
            </div>
            <div>
              <LinkButton
                title={
                  userData.loginIdType === LOGIN_ID_TYPE.EMAIL
                    ? userData.validatedEmail
                    : userData.loginId
                }
                to={{
                  pathname: `${API_SA_BASE_URL}${API_ENDPOINTS.SA_PROFILE}`,
                }}
                target="_blank"
                underlined={false}
                icon={SVG_ICONS.GREATE_THAN_SML}
                iconSize={24}
                textClassName="profile__popup__email"
              />
            </div>
            <div className="horizontal-divider" />
            <div>
              <LinkButton
                textClassName="signout"
                title={t("PRIVACY_HEADER_SIGNOUT")}
                to={ROUTES.ROOT}
                underlined={false}
                onClick={() => {
                  props.onProfileClick && props.onProfileClick();
                  logout();
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
