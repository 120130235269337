import { COOKIE_SC_ATKN } from "../constants/storage";
import { Cookie } from "../services/storage";
import { AgeRestricted } from "../types/age-restriction";
import { UtilsDateTime } from "./UtilsDateTIme";

export const REQUEST_RESTRICTED_13_COUNTRIES = ["USA"];
export const REQUEST_RESTRICTED_14_COUNTRIES = ["KOR"];

export const EU_AGE_RESTRICTED: AgeRestricted[] = [
  { countryCode: "ALB", age: 18 },
  { countryCode: "AND", age: 16 },
  { countryCode: "AUT", age: 14 },
  { countryCode: "BEL", age: 13 },
  { countryCode: "BIH", age: 13 },
  { countryCode: "BGR", age: 14 },
  { countryCode: "HRV", age: 16 },
  { countryCode: "CYP", age: 14 },
  { countryCode: "CZE", age: 15 },
  { countryCode: "DNK", age: 15 },
  { countryCode: "EST", age: 13 },
  { countryCode: "FRO", age: 13 },
  { countryCode: "FIN", age: 13 },
  { countryCode: "FRA", age: 15 },
  { countryCode: "DEU", age: 16 },
  { countryCode: "GIB", age: 14 },
  { countryCode: "GRC", age: 15 },
  { countryCode: "GRL", age: 15 },
  { countryCode: "VAT", age: 13 },
  { countryCode: "HUN", age: 16 },
  { countryCode: "ISL", age: 13 },
  { countryCode: "IRL", age: 16 },
  { countryCode: "ITA", age: 14 },
  { countryCode: "XKX", age: 16 },
  { countryCode: "LVA", age: 13 },
  { countryCode: "LIE", age: 16 },
  { countryCode: "LTU", age: 14 },
  { countryCode: "LUX", age: 16 },
  { countryCode: "MLT", age: 13 },
  { countryCode: "MCO", age: 16 },
  { countryCode: "MNE", age: 18 },
  { countryCode: "NLD", age: 16 },
  { countryCode: "MKD", age: 14 },
  { countryCode: "NOR", age: 15 },
  { countryCode: "POL", age: 16 },
  { countryCode: "PRT", age: 13 },
  { countryCode: "ROU", age: 16 },
  { countryCode: "SMR", age: 16 },
  { countryCode: "SRB", age: 15 },
  { countryCode: "SVK", age: 16 },
  { countryCode: "SVN", age: 16 },
  { countryCode: "ESP", age: 14 },
  { countryCode: "SWE", age: 16 },
  { countryCode: "CHE", age: 16 },
  { countryCode: "GBR", age: 14 },
];

export const UtilsUser = {
  isUserLoggedIn: () => {
    return !!Cookie.get(COOKIE_SC_ATKN);
  },

  isUserRestricted: (
    countryCode: string,
    userBirthdate?: string,
    isEU = false
  ) => {
    if (
      REQUEST_RESTRICTED_13_COUNTRIES.indexOf(countryCode) >= 0 &&
      userBirthdate
    ) {
      const userAge = UtilsUser.getUserAge(userBirthdate);
      if (userAge < 13) return true;
    }

    if (
      REQUEST_RESTRICTED_14_COUNTRIES.indexOf(countryCode) >= 0 &&
      userBirthdate
    ) {
      const userAge = UtilsUser.getUserAge(userBirthdate);
      if (userAge < 14) return true;
    }

    if (isEU && userBirthdate) {
      const userAge = UtilsUser.getUserAge(userBirthdate);
      const ageRestriction = EU_AGE_RESTRICTED.filter(
        (data) => data.countryCode === countryCode
      );

      if (ageRestriction.length > 0) {
        return userAge < ageRestriction[0].age;
      }
    }

    return false;
  },
  getUserAge: (date: string) => {
    const now = new Date();
    let birthDate = UtilsDateTime.parseDate(date, true);

    // get time diff
    let diff = (now.getTime() - birthDate.getTime()) / 1000;

    // get diff in years for age
    diff = Math.floor(diff / (365.25 * 24 * 60 * 60));

    return diff;
  },
};
