import { CustomAction } from "..";
import { ServiceEntity } from "../../types/services";
import { SERVICES_ACTION_TYPES } from "../actions-types";

export interface ServicesState {
  services: ServiceEntity[];
  isServicesLoading: boolean;
}

const defaultState: ServicesState = {
  services: [],
  isServicesLoading: true,
};

const serviceReducer = (state = defaultState, action: CustomAction) => {
  switch (action.type) {
    case SERVICES_ACTION_TYPES.SET_SERVICES:
      return {
        ...state,
        services: action.data,
        isServicesLoading: false,
      };
    case SERVICES_ACTION_TYPES.SET_SERVICES_LOADING:
      return {
        ...state,
        isServicesLoading: true,
      };
    default:
      return state;
  }
};

export default serviceReducer;
