import React from "react";
import { Banner } from "./Sections/Banner/Banner";
import ManagePrivacy from "./Sections/ManagePrivacy/ManagePrivacy";
import LearnMore from "./Sections/LearnMore/LearnMore";
import PersonalDataManagement from "./Sections/PersonalDataManagement/PersonalDataManagement";

const LandingPage: React.FC = () => {
  const handleScrollOnClick = () => {
    const body = document.getElementById("main-body");
    const manage = document.getElementById("manage-privacy");
    body?.scrollTo({ top: manage?.offsetTop, behavior: "smooth" });
  };

  return (
    <>
      <Banner onClick={handleScrollOnClick} data-testId="landingPage-banner" />
      <ManagePrivacy />
      <LearnMore />
      <PersonalDataManagement />
    </>
  );
};

export default LandingPage;
