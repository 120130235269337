import { Regions } from "../data/regions";
import ISO from "iso-3166-2";
import {
  DEFAULT_COUNTRY_CODE,
  ISO3CountryCodesMap,
  SUPPORTED_PPS,
} from "../constants/locale";
import { forEach, includes, isString, map } from "lodash";
import { CountryWithRegion, Language, LANGUAGE_VIEW } from "../types/countries";

export enum COUNTRY_USAGE {
  SITE_LANGUAGE = "SITE_LANGUAGE",
  EMAIL = "EMAIL",
}

export const replaceUnicodeWithSlash = (str: string) => {
  return str.replace(/&amp;#x2F;/g, "/");
};

export const UtilsLocale = {
  checkIfCountryIsFromRegion: (
    countryCode: string,
    countryList: any
  ): boolean => countryList.includes(countryCode),

  getCountryDataFromJson: ({
    countryCode = "",
    languageCode = "",
    languageView = LANGUAGE_VIEW.SITE_LANGUAGE,
  } = {}) => {
    let countryR: CountryWithRegion = {
      code: "",
      name: "",
      region: "",
      languages: [],
    };
    Regions.forEach((region) => {
      region.countries.forEach((country) => {
        if (countryCode === country.code) {
          // country.region = region.name; // add country's region on return data
          countryR = { ...country, region: region.name };
        }
        return [];
      });

      return countryR;
    });

    // console.log({ countryRes });

    countryR =
      (UtilsLocale.validateLanguageView(
        countryR,
        languageCode,
        languageView
      ) as CountryWithRegion) || null;

    return countryR ? countryR : null;
  },

  validateCountry: (
    countryCode: string | any,
    usage = COUNTRY_USAGE.SITE_LANGUAGE
  ) => {
    if (isString(countryCode) && countryCode.length === 3) {
      try {
        const oRegion = ISO.subdivision(countryCode.toLowerCase());
        return oRegion?.countryCode.toLowerCase();
      } catch (e: any) {
        console.warn("Invalid country");
        return DEFAULT_COUNTRY_CODE;
      }
    }

    let isCountryValid: boolean = false;
    forEach(Regions, (region) => {
      if (
        countryCode &&
        includes(map(region.countries, "code"), countryCode.toUpperCase())
      ) {
        isCountryValid = true;
      }
    });

    if (!isCountryValid) {
      if (usage === COUNTRY_USAGE.EMAIL) return null;
      if (usage === COUNTRY_USAGE.SITE_LANGUAGE) return DEFAULT_COUNTRY_CODE;
    }
  },

  validateLanguageView: (
    countryRes: CountryWithRegion | null,
    sLanguageCode: string,
    languageView: LANGUAGE_VIEW
  ) => {
    if (languageView === LANGUAGE_VIEW.SITE_LANGUAGE) {
      countryRes &&
        countryRes.languages.map((cLanguage: Language) => {
          if (
            cLanguage.code === sLanguageCode &&
            cLanguage.languageView &&
            cLanguage.languageView === LANGUAGE_VIEW.PP_LANGUAGE
          ) {
            countryRes = null;
            return {};
          }
          return cLanguage;
        });
    }
    if (languageView === LANGUAGE_VIEW.PP_LANGUAGE) {
      countryRes &&
        countryRes.languages.map((cLanguage: Language) => {
          if (
            cLanguage.code === sLanguageCode &&
            cLanguage.languageView &&
            cLanguage.languageView === LANGUAGE_VIEW.SITE_LANGUAGE
          ) {
            countryRes = null;
            return {};
          }
          return cLanguage;
        });
    }
    return countryRes;
  },

  convertISO3toISO2: (countryCode: string) => {
    const ISO2Code = ISO3CountryCodesMap[countryCode];
    return ISO2Code ? ISO2Code : countryCode;
  },

  convertISO2toISO3: (countryCode: string) => {
    const ISO3Code = Object.keys(ISO3CountryCodesMap).find(
      (key) => ISO3CountryCodesMap[key] === countryCode
    );

    return ISO3Code ? ISO3Code : countryCode;
  },

  hasPrivacyPolicy: (langCountryCode: string) => {
    return SUPPORTED_PPS.includes(langCountryCode);
  },
};
