import { useDispatch, useSelector } from "react-redux";
import {
  API_BASE_URL,
  API_ENDPOINTS,
  API_PUBLIC_AUTH_TOKEN,
} from "../constants/api";
import { Survey, User } from "../types/user";
import {
  DEFAULT_COUNTRY_CODE,
  EUR_COUNTRIES,
  EU_COUNTRIES,
  USA_COUNTRIES,
  US_COUNTRIES,
} from "../constants/locale";

import Http from "../services/http";
import { ApplicationState } from "../store";
import {
  actionSetGeoIpLoading,
  actionSetGetDataUserLoading,
  actionSetRestriction,
  actionSetSACountry,
  actionSetUser,
  actionSetUserCountry,
  actionSetUserRegion,
  actionSetUserAnsweredSurvey,
  actionSetGetSurveyResponsePending,
  actionSetSubmitSurveyPending,
} from "../store/actions/user-actions";
import { UserState } from "../store/reducers/user-reducer";
import { UtilsLocale } from "../utils/UtilsLocale";
import { LocalStorage } from "../services/storage";
import {
  GEO_IP,
    RESTRICTED,
  USER_DATA_NAMESPACE,
  USER_SESSION_ID,
} from "../constants/storage";
import { UtilsUser } from "../utils/UtilsUser";
import { generateToken } from "../utils/UtilsTokenGenerator";

export const useUserHooks = () => {
  const dispatch = useDispatch();
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const { userCountryCode, userData, userRegion } = userState;

  const loadUserCountryCode = async () => {
    try {
      dispatch(actionSetGeoIpLoading(true));
      const url = `${API_BASE_URL}${API_ENDPOINTS.GEOIP}`;
      const res = await fetch(url, {
        method: "GET",
        headers: { Authorization: API_PUBLIC_AUTH_TOKEN },
      });

      const { headers } = res;

      const countryCode =
        headers && headers.get("country")
          ? headers.get("country") || ""
          : DEFAULT_COUNTRY_CODE;

      // NOTE: Geo IP state does not persist so we set it to session storage.
      //       To be used for download/deletion of apps and services (SDAP-1247)
      sessionStorage.setItem(GEO_IP, countryCode);

      dispatch(actionSetUserCountry(countryCode));

      const isUsCountry: boolean = UtilsLocale.checkIfCountryIsFromRegion(
        countryCode,
        US_COUNTRIES
      );

      const isEuropeanCountry: boolean = UtilsLocale.checkIfCountryIsFromRegion(
        countryCode,
        EU_COUNTRIES
      );

      dispatch(
        actionSetUserRegion({
          isFromUS: isUsCountry,
          isFromEurope: isEuropeanCountry,
        })
      );
    } catch (e) {
      dispatch(actionSetGeoIpLoading(false));
      console.error(e);
    }
  };

  const getUserData = async () => {
    try {
      dispatch(actionSetGetDataUserLoading(true));
      const url = `${API_BASE_URL}${API_ENDPOINTS.PROFILE}`;
      const data = await Http.get(url);
      const userData: User = {
        birthDate: data.birthday,
        countryCode: data.countryCode,
        joinedDate: data.joinedDate,
        loginId: data.loginId,
        loginIdType: data.loginIdType,
        mail: data.mail,
        name: data.name,
        validatedEmail: data.validatedEmail,
        profileImageUrl: data.profileImageUrl,
      };

      LocalStorage.set(USER_DATA_NAMESPACE, JSON.stringify(userData));

      const isSAFromEurope = UtilsLocale.checkIfCountryIsFromRegion(userData.countryCode, EUR_COUNTRIES);

      const isRestricted = UtilsUser.isUserRestricted(
        userData.countryCode,
        userData.birthDate,
        isSAFromEurope
      );
      sessionStorage.setItem(RESTRICTED, String(isRestricted));
      const isSAFromAmerica = UtilsLocale.checkIfCountryIsFromRegion(
        userData.countryCode,
        USA_COUNTRIES,
      );
      const isSAUSA = userData.countryCode === "USA";

      const authState = generateToken(12);
      LocalStorage.set(USER_SESSION_ID, authState);

      dispatch(actionSetUser(userData));
      dispatch(
        actionSetSACountry({
          isSAFromAmerica: isSAFromAmerica,
          isSAFromUSA: isSAUSA,
          isSAFromEurope: isSAFromEurope
        })
      );
      dispatch(actionSetRestriction(isRestricted));
      dispatch(actionSetGetDataUserLoading(false));

      return userData;
    } catch (e: any) {
      dispatch(actionSetGetDataUserLoading(false));
      LocalStorage.remove(USER_DATA_NAMESPACE);

      return;
    }
  };

  const getUserSurveyResponse = async () => {
    try {
      dispatch(actionSetGetSurveyResponsePending(true));
      const url = `${API_BASE_URL}/${API_ENDPOINTS.SURVEY}`;

      const extraHeaders = {
        Authorization: API_PUBLIC_AUTH_TOKEN,
      };

      const data = await Http.get(url, extraHeaders);

      dispatch(actionSetUserAnsweredSurvey(data.answered));
      dispatch(actionSetGetSurveyResponsePending(false));
    } catch (e: any) {
      dispatch(actionSetGetDataUserLoading(false));
    }
  };

  const submitUserSurvey = async (formData: Survey) => {
    try {
      dispatch(actionSetSubmitSurveyPending(true));
      const url = `${API_BASE_URL}/${API_ENDPOINTS.SURVEY}`;

      const extraHeaders = {
        Authorization: API_PUBLIC_AUTH_TOKEN,
      };

      const userSurveyData: Survey = {
        survey_id: 1,
        answer_1: formData.answer_1,
        comments: formData.comments?.trim(),
      };

      await Http.post(url, userSurveyData, extraHeaders);

      dispatch(actionSetUserAnsweredSurvey(true));
      dispatch(actionSetSubmitSurveyPending(false));
    } catch (e: any) {
      dispatch(actionSetGetDataUserLoading(false));
    }
  };

  return {
    loadUserCountryCode,
    userRegion,
    userData,
    userCountryCode,
    getUserData,
    submitUserSurvey,
    getUserSurveyResponse,
  };
};
