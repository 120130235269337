import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookie } from "../../hooks/cookie-hooks";
import { UtilsKeyboardAccessibility } from "../../utils/UtilsKeyboardAccessibility";
import { Col, Container, Row } from "../Grid/Grid";

import Modal from "../__common/Modal/Modal";
import { Svg, SVG_ICONS } from "../__common/Svg/Svg";
import { Typography } from "../__common/Typography/Typography";
import { Button } from "../__common/_controls";
import { Switch } from "../__common/_controls/Switch/Switch";

interface Props {
  isOpen: boolean;
  onHide?: () => void;
}

export const ManageCookieModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { onHide, isOpen } = props;
  const {
    setCookiePreference,
    isFCAllowed,
    isPCAllowed,
    setFCAllowed,
    setPCAllowed,
  } = useCookie();
  const [isEssentialOpen, setEssentialOpen] = useState(false);

  const [isFunctionalOpen, setFunctionalOpen] = useState(false);

  const [isPerformanceOpen, setPerformanceOpen] = useState(false);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setModalOpen(isOpen);

    if (isOpen) {
      UtilsKeyboardAccessibility.setFocus("manage-cookies-modal");
    }
  }, [isOpen]);

  useEffect(() => {
    UtilsKeyboardAccessibility.addTabEventListener(
      "agree-cookies-button",
      "manage-cookies-modal"
    );

    return () => {
      UtilsKeyboardAccessibility.removeTabEventListener(
        "agree-cookies-button",
        "manage-cookies-modal"
      );
    };
  }, []);

  return (
    <div className="manage-cookie" id="manage-cookies-modal" tabIndex={0}>
      <Modal show={isModalOpen}>
        <Modal.Header
          headerTestid="modal-header"
          onHide={() => {
            setModalOpen(false);
            if (onHide) {
              onHide();
            } else {
              setCookiePreference(false, false);
            }
          }}
        >
          <Modal.Title>
            {t("PRIVACY_COOKIE_NOTICE_EU_BTN_MANAGE_COOKIES")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="manage-cookie__top-text">
            <Typography variant="body2">
              {t("PRIVACY_COOKIE_SETTINGS_DESC")}
            </Typography>
          </div>
          <div className="manage-cookie__content">
            <div className="manage-cookie__content__box">
              <div className="manage-cookie__content__box__head">
                <Typography variant="title3">
                  {t("PRIVACY_COOKIE_SETTINGS_ESSENTIAL_TITLE")}
                </Typography>
              </div>
              <div className="manage-cookie__content__box__description">
                <Typography variant="body2">
                  {t("PRIVACY_COOKIE_SETTINGS_ESSENTIAL_DESC")}
                </Typography>
              </div>
              <div
                className={classNames("manage-cookie__content__box__hide", {
                  open: isEssentialOpen,
                })}
              >
                <button
                  data-testid="toggle-essential-btn"
                  onClick={() => setEssentialOpen(!isEssentialOpen)}
                >
                  <Typography variant="body3" component="span">
                    {isEssentialOpen
                      ? t("PRIVACY_COOKIE_SETTINGS_HIDE_COOKIES")
                      : t("PRIVACY_COOKIE_SETTINGS_VIEW_COOKIES")}
                    <Svg icon={SVG_ICONS.ARROW_DOWN} width={12} height={12} />
                  </Typography>
                </button>
              </div>
              <div
                className={classNames("manage-cookie__content__box__table", {
                  open: isEssentialOpen,
                })}
              >
                <Container>
                  <Row>
                    <Col md={4} sm={12}>
                      <Typography variant="body2" weight="w700">
                        {t("PRIVACY_COOKIE_SETTINGS_COMPANY")}
                      </Typography>
                    </Col>
                    <Col md={8} sm={12}>
                      <Typography variant="body3" weight="w400">
                        Samsung Electronics
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={12}>
                      <Typography variant="body2" weight="w700">
                        {t("PRIVACY_COOKIE_SETTINGS_DOMAIN")}
                      </Typography>
                    </Col>
                    <Col md={8} sm={12}>
                      <Typography variant="body3" weight="w400">
                        privacy.samsung.com
                      </Typography>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="manage-cookie__content__box">
              <div className="manage-cookie__content__box__head">
                <Typography variant="title3">
                  {t("PRIVACY_COOKIE_SETTINGS_FUNCTIONAL_TITLE")}
                </Typography>
                <Switch
                  testId="toggleSwitch"
                  value={isFCAllowed}
                  onToggle={(val: boolean) => setFCAllowed(val)}
                />
              </div>
              <div className="manage-cookie__content__box__description">
                <Typography variant="body2">
                  {t("PRIVACY_COOKIE_SETTINGS_FUNCTIONAL_DESC")}
                </Typography>
              </div>
              <div
                className={classNames("manage-cookie__content__box__hide", {
                  open: isFunctionalOpen,
                })}
              >
                <button
                  data-testid="toggle-functionalOpen"
                  onClick={() => {
                    setFunctionalOpen(!isFunctionalOpen);
                  }}
                >
                  <Typography variant="body3" component="span">
                    {isFunctionalOpen
                      ? t("PRIVACY_COOKIE_SETTINGS_HIDE_COOKIES")
                      : t("PRIVACY_COOKIE_SETTINGS_VIEW_COOKIES")}
                    <Svg icon={SVG_ICONS.ARROW_DOWN} width={12} height={12} />
                  </Typography>
                </button>
              </div>
              <div
                className={classNames("manage-cookie__content__box__table", {
                  open: isFunctionalOpen,
                })}
              >
                <Container>
                  <Row className="manage-cookie__content__box__table__wSwitch">
                    <Col md={4} sm={12}>
                      <Typography variant="body2" weight="w700">
                        {t("PRIVACY_COOKIE_SETTINGS_COMPANY")}
                      </Typography>
                    </Col>
                    <Col md={6} sm={12}>
                      <Typography variant="body3" weight="w400">
                        Samsung Electronics
                      </Typography>
                    </Col>
                    <Col
                      md={2}
                      sm={12}
                      className="manage-cookie__content__box__table__wSwitch__switch"
                    >
                      <Switch
                        testId="toggle-fc"
                        value={isFCAllowed}
                        onToggle={(val: boolean) => setFCAllowed(val)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={12}>
                      <Typography variant="body2" weight="w700">
                        {t("PRIVACY_COOKIE_SETTINGS_DOMAIN")}
                      </Typography>
                    </Col>
                    <Col md={6} sm={12}>
                      <Typography variant="body3" weight="w400">
                        privacy.samsung.com
                      </Typography>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="manage-cookie__content__box">
              <div className="manage-cookie__content__box__head">
                <Typography variant="title3">
                  {t("PRIVACY_COOKIE_SETTINGS_PERF_TITLE")}
                </Typography>
                <Switch
                  testId="toggle-pcAllowed"
                  value={isPCAllowed}
                  onToggle={(val: boolean) => setPCAllowed(val)}
                />
              </div>
              <div className="manage-cookie__content__box__description">
                <Typography variant="body2">
                  {t("PRIVACY_COOKIE_SETTINGS_PERF_DESC")}
                </Typography>
              </div>
              <div
                className={classNames("manage-cookie__content__box__hide", {
                  open: isPerformanceOpen,
                })}
              >
                <button
                  data-testid="toggle-performanceOpen"
                  onClick={() => setPerformanceOpen(!isPerformanceOpen)}
                >
                  <Typography variant="body3" component="span">
                    {isPerformanceOpen
                      ? t("PRIVACY_COOKIE_SETTINGS_HIDE_COOKIES")
                      : t("PRIVACY_COOKIE_SETTINGS_VIEW_COOKIES")}
                    <Svg icon={SVG_ICONS.ARROW_DOWN} width={12} height={12} />
                  </Typography>
                </button>
              </div>
              <div
                className={classNames("manage-cookie__content__box__table", {
                  open: isPerformanceOpen,
                })}
              >
                <Container>
                  <Row className="manage-cookie__content__box__table__wSwitch">
                    <Col md={4} sm={12}>
                      <Typography variant="body2" weight="w700">
                        {t("PRIVACY_COOKIE_SETTINGS_COMPANY")}
                      </Typography>
                    </Col>
                    <Col md={6} sm={12}>
                      <Typography variant="body3" weight="w400">
                        Google Inc.
                      </Typography>
                    </Col>
                    <Col
                      md={2}
                      sm={12}
                      className="manage-cookie__content__box__table__wSwitch__switch"
                    >
                      <Switch
                        testId="toggle-pcAllowed2"
                        value={isPCAllowed}
                        onToggle={(val: boolean) => setPCAllowed(val)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={12}>
                      <Typography variant="body2" weight="w700">
                        {t("PRIVACY_COOKIE_SETTINGS_DOMAIN")}
                      </Typography>
                    </Col>
                    <Col md={6} sm={12}>
                      <Typography variant="body3" weight="w400">
                        ssl.google-analytics.com
                      </Typography>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-container">
            <Button
              type="secondary"
              testId="cancel-btn"
              title={t("PRIVACY_MODAL_BTN_CANCEL_ALTTEXT")}
              onClick={() => {
                setModalOpen(false);
                if (onHide) {
                  onHide();
                } else {
                  setCookiePreference(false, false);
                }
              }}
            ></Button>
          </div>
          <div className="button-container">
            <Button
              testId="agree-cookies-button"
              title={t("PRIVACY_COOKIE_SETTINGS_AGREE")}
              onClick={() => {
                setModalOpen(false);
                if (onHide) onHide();
                setCookiePreference(isFCAllowed, isPCAllowed);
              }}
              id="agree-cookies-button"
            ></Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
