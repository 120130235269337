import { CLIENT_ID } from "./appConstants";

// const stg1 = 'https://stg-account.samsung.com';
const samsungAccountBaseUrl = "https://account.samsung.com";
const isLocalEnv = window.location.hostname === "localhost" || false;
const locationOrigin = window.location.origin;

const config = {
  APP_BASE_URL: `${locationOrigin}/?success=1#`,
  APP_BASE_URL_NO_PARAMS: `${locationOrigin}`,
  APP_MY_DATA_URL_NO_PARAMS: `${locationOrigin}/#/mydata/downloads`,
  SERVER_BASE_URL: isLocalEnv ? "http://localhost:8080" : locationOrigin,
  SA_PROFILE: `${samsungAccountBaseUrl}/membership/`,
  SA_SIGNIN_GATE: `${samsungAccountBaseUrl}/accounts/v1/SDAP/signInGate`,
  SA_SIGNOUT_GATE: `${samsungAccountBaseUrl}/accounts/v1/SDAP/signOutGate`,
  SA_CONFIRM_PASSWORD_GATE: `${samsungAccountBaseUrl}/accounts/v1/SDAP/confirmPasswordGate`,
  AUTH_SIGNIN_URL: `${locationOrigin}/user/v2/tokens/access?returnUrl=${locationOrigin}/?success=1&appId=${CLIENT_ID}`,
  AUTH_SIGNOUT_URL: `${locationOrigin}/user/v2/tokens/expire?returnUrl=${locationOrigin}/&appId=${CLIENT_ID}`,
  MYDATA_URL: `${locationOrigin}/user/v2/tokens/access?returnUrl=${locationOrigin}/?success=1%23/my-data&appId=${CLIENT_ID}`,
  TERMS_PP_URL: `${samsungAccountBaseUrl}/membership/pp`,
  PRIVACY_POLICY_URL: `${samsungAccountBaseUrl}/membership/terms/privacypolicy`,
  TERMS_AND_CONDITION_URL: `${samsungAccountBaseUrl}/membership/terms`,
  CONTACT_US_URL: "https://help.content.samsung.com/csweb/auth/gosupport.do",
  LOCAL_BASE_URL: "http://localhost:8080",
  ACR_VALUES: "urn:samsungaccount:acr:aal1",
};

export default config;
