import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { layoutReducer, LayoutState } from "./reducers/layout-reducer";
import myDataReducer, { MyDataState } from "./reducers/mydata-reducer";
import privacyReducer, { PrivacyState } from "./reducers/privacy-reducer";
import serviceReducer, { ServicesState } from "./reducers/services-reducer";
import userReducer, { UserState } from "./reducers/user-reducer";

export interface CustomAction {
  type: string;
  data?: any;
}

export interface ApplicationState {
  user: UserState;
  services: ServicesState;
  myData: MyDataState;
  layout: LayoutState;
  privacy: PrivacyState;
}

const reducer = combineReducers({
  user: userReducer,
  services: serviceReducer,
  myData: myDataReducer,
  layout: layoutReducer,
  privacy: privacyReducer,
});

export const store = createStore(reducer, composeWithDevTools());
