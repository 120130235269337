import React, { useState, useEffect } from "react";
import { Typography } from "../../../../../components/__common/Typography/Typography";
import { Container } from "../../../../../components/Grid/Grid";
import Tabs from "../../../../../components/__common/_controls/Tab/Tab";
import LearnMoreMap from "./LearnMoreMap";

import {
  CountriesWithLocalPrivacy,
  HubRegions,
  MapRegions,
} from "../../../../../data/landing-page";
import { ERegions, MapRegion } from "../../../../../types/landing-page";
import PrivacyRightsModal from "../../PrivacyRightsModal/PrivacyRightsModal";
import { useTranslation } from "react-i18next";
import "./LearnMore.scss";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { UserState } from "../../../../../store/reducers/user-reducer";
import FocusTrapping from "../../../../../components/__hoc/FocusTrapping";

const LearnMore: React.FC = () => {
  const [showPrivacyRightsModal, setShowPrivacyRightsModal] =
    useState<boolean>(false);
  const { userCountryCode } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  const { t } = useTranslation();
  const [selectedRegion, setSelectedRegion] = useState<MapRegion | undefined>(
    undefined
  );
  const handleToggleModal = () => {
    setShowPrivacyRightsModal(!showPrivacyRightsModal);
  };

  const hasLocalSite = CountriesWithLocalPrivacy.some(
    (region) => region.code === userCountryCode
  );

  const getMapRegion = () => {
    let landingPageRegion = MapRegions.find(
      (region) => region.code === userCountryCode
    );

    const region = HubRegions.filter((region) =>
      region.countries.some((country) => country.code === userCountryCode)
    )[0];

    // Global countries with no local sites and added EU countries except turkey
    if (!hasLocalSite) {
      setSelectedRegion(MapRegions[0]);
      return;
    }

    // if specific country (e.g. US, China, Turkey)
    if (landingPageRegion) {
      setSelectedRegion(landingPageRegion);
      return;
    }
    // if specific region
    if (
      region.code === ERegions.LATIN_AMERICA ||
      region.code === ERegions.MENA ||
      region.code === ERegions.ASIA_PACIFIC
    ) {
      landingPageRegion = MapRegions.find(
        (hubRegion) => hubRegion.code === region.code
      );
      setSelectedRegion(landingPageRegion);
    }
  };

  useEffect(() => {
    if (userCountryCode) {
      getMapRegion();
    }
  }, [userCountryCode]);

  return (
    <>
      <div className="learn-more" id="learn-more">
        <Container>
          <Typography variant="h1" className="learn-more__title sharp-sans">
            {t("PRIVACY_HUB_LEARN_MORE_TITLE")}
          </Typography>
          <Typography
            variant="title3"
            className="learn-more__subtitle"
            weight="wMedium"
          >
            {t("PRIVACY_HUB_LEARN_MORE_DESC")}
          </Typography>
          <Tabs className="learn-more__tabs">
            {MapRegions.map((region, i) => (
              <Tabs.Tab
                radioTestId="region-radio"
                key={region.code}
                name="regions_tab"
                title={
                  region.name === "Global"
                    ? t("PRIVACY_HUB_LEARN_MORE_OTHER_REGIONS")
                    : region.name
                }
                type="2depth"
                checked={selectedRegion?.code === region.code}
                onChange={() => setSelectedRegion(region)}
              />
            ))}
          </Tabs>
          <Container className="learn-more__map svg">
            <LearnMoreMap
              testId="toggle-modal-anchor"
              code={selectedRegion?.code}
              handleToggleModal={handleToggleModal}
            />
          </Container>
        </Container>
      </div>
      {showPrivacyRightsModal && selectedRegion && (
        <FocusTrapping
          isActive={showPrivacyRightsModal}
          elements="div.modal-body, div.title2, button, input, svg"
        >
          <PrivacyRightsModal
            show={showPrivacyRightsModal}
            onClickClose={handleToggleModal}
            country={selectedRegion}
          />
        </FocusTrapping>
      )}
    </>
  );
};

export default LearnMore;
