import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../components/__common/Loading/Loading";
import { REQUEST_NUMBER } from "../../../constants/storage";
import { URLS } from "../../../constants/urls";
import { useAuth } from "../../../hooks/auth-hooks";
import { useQuery } from "../../../hooks/query-hooks";
import { Cookie } from "../../../services/storage";
import { ApplicationState } from "../../../store";
import { UserState } from "../../../store/reducers/user-reducer";

export const Login: React.FC = () => {
  const query: any = useQuery();
  const { login } = useAuth();
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  useEffect(() => {
    const body = document.body;
    body.classList.add("modal-open");

    return () => {
      body.classList.remove("modal-open");
    };
  });

  useEffect(
    () => {
      if (!userState.isGeoIPLoading) {
        const requestNumber = Cookie.get(REQUEST_NUMBER);
        let redirectUri = query.get("redirect");

        if (requestNumber) {
          redirectUri = redirectUri.replace(`/${requestNumber}`, "");
        }

        if (redirectUri.includes(URLS.OLD_MYDATA)) {
          redirectUri = URLS.MYDATA;
        }

        login(redirectUri);
      }
    },
    // eslint-disable-next-line
    [userState.isGeoIPLoading]
  );

  return <Loading />;
};
