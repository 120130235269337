import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HIGH_CONTRAST } from "../constants/storage";
import { Cookie } from "../services/storage";
import { ApplicationState } from "../store";
import {
  actionSetHighContrast,
  actionSetSelectedCountry,
  actionSetTTSVoices,
} from "../store/actions/layout-actions";
import { LayoutState } from "../store/reducers/layout-reducer";
import { useCookie } from "./cookie-hooks";
import { useSpeechSynthesis } from "react-speech-kit";
import { HubCountry } from "../types/landing-page";
import { UserState } from "../store/reducers/user-reducer";
import { HubRegions } from "../data/landing-page";

export const useLayout = () => {
  const dispatch = useDispatch();
  const { isFCAllowed } = useCookie();

  const { voices } = useSpeechSynthesis();
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { selectedCountry } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { userCountryCode } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  useEffect(() => {
    if (layoutState.isHighContrast) {
      document.documentElement.setAttribute("data-theme", "hc");
    } else {
      document.documentElement.removeAttribute("data-theme");
    }
  }, [layoutState.isHighContrast]);

  const setTheme = (isHc: boolean) => {
    dispatch(actionSetHighContrast(isHc));

    if (isFCAllowed) {
      Cookie.set(HIGH_CONTRAST, isHc.toString());
    }
  };

  useEffect(() => {
    if (voices.length > 0) {
      dispatch(actionSetTTSVoices(voices));
    }
  }, [voices]);

  const setSelectedCountry = (data: HubCountry) => {
    dispatch(actionSetSelectedCountry(data));
  };

  const getUserCountry = () => {
    const countryCode = selectedCountry.code
      ? selectedCountry.code
      : userCountryCode;

    const region = HubRegions.filter((region) =>
      region.countries.some((country) => country.code === countryCode)
    )[0];
    const country = region.countries.filter(
      (country) => country.code === countryCode
    )[0];

    setSelectedCountry(country);

    return country;
  };

  return {
    setTheme,
    setSelectedCountry,
    getUserCountry,
  };
};
