// export const error400 = {
//   // errorIcon: { IconError },
//   errorId: 'error_400',
//   errorAltText: 'PRIVACY_MYDATA_BODY_ERR_400_MSG_ALTTEXT',
//   errorTitle: 'PRIVACY_MYDATA_BODY_ERR_400_MSG',
//   errorMessage: 'PRIVACY_MYDATA_BODY_ERR_400_MSG_EXP'
// };

// export const error403 = {
//   // errorIcon: { IconError },
//   errorId: 'error_403',
//   errorAltText: 'PRIVACY_MYDATA_BODY_ERR_403_MSG_ALTTEXT',
//   errorTitle: 'PRIVACY_MYDATA_BODY_ERR_403_MSG',
//   errorMessage: 'PRIVACY_MYDATA_BODY_ERR_403_MSG_EXP'
// };

// export const error404 = {
//   // errorIcon: { IconError },
//   errorId: 'error_404',
//   errorAltText: 'PRIVACY_MYDATA_BODY_ERR_404_MSG_ALTTEXT',
//   errorTitle: 'PRIVACY_MYDATA_BODY_ERR_404_MSG',
//   errorMessage: 'PRIVACY_MYDATA_BODY_ERR_404_MSG_EXP'
// };

// export const error500 = {
//   // errorIcon: { IconError },
//   errorId: 'error_500',
//   errorAltText: 'PRIVACY_MYDATA_BODY_ERR_500_MSG_ALTTEXT',
//   errorTitle: 'PRIVACY_MYDATA_BODY_ERR_500_MSG',
//   errorMessage: 'PRIVACY_MYDATA_BODY_ERR_500_MSG_EXP'
// };

// export const error502 = {
//   // errorIcon: { IconError },
//   errorId: 'error_502',
//   errorAltText: 'PRIVACY_MYDATA_BODY_ERR_502_MSG_ALTTEXT',
//   errorTitle: 'PRIVACY_MYDATA_BODY_ERR_502_MSG',
//   errorMessage: 'PRIVACY_MYDATA_BODY_ERR_502_MSG_EXP'
// };

// export const error503 = {
//   // errorIcon: { IconError },
//   errorId: 'error_503',
//   errorAltText: 'PRIVACY_MYDATA_BODY_ERR_503_MSG_ALTTEXT',
//   errorTitle: 'PRIVACY_MYDATA_BODY_ERR_503_MSG',
//   errorMessage: 'PRIVACY_MYDATA_BODY_ERR_503_MSG_EXP'
// };

// export const maintenance = {
//   // errorIcon: { IconError },
//   errorId: 'maintenance',
//   errorAltText: 'PRIVACY_MYDATA_BODY_ERR_MAINTENANCE_MSG_ALTTEXT',
//   errorTitle: 'PRIVACY_MYDATA_BODY_ERR_MAINTENANCE_MSG',
//   errorMessage: 'PRIVACY_MYDATA_BODY_ERR_MAINTENANCE_MSG_EXP'
// };

export const error400 = {
  // errorIcon: { IconError },
  errorId: "error_400",
  errorAltText: "PRIVACY_ERR_400_MSG_ALTTEXT",
  errorTitle: "PRIVACY_ERR_400_MSG",
  errorMessage: "PRIVACY_ERR_400_MSG_EXP",
};

export const error403 = {
  // errorIcon: { IconError },
  errorId: "error_403",
  errorAltText: "PRIVACY_ERR_403_MSG_ALTTEXT",
  errorTitle: "PRIVACY_ERR_403_MSG",
  errorMessage: "PRIVACY_ERR_403_MSG_EXP",
};

export const error404 = {
  // errorIcon: { IconError },
  errorId: "error_404",
  errorAltText: "PRIVACY_ERR_404_MSG_ALTTEXT",
  errorTitle: "PRIVACY_ERR_404_MSG",
  errorMessage: "PRIVACY_ERR_404_MSG_EXP",
};

export const error500 = {
  // errorIcon: { IconError },
  errorId: "error_500",
  errorAltText: "PRIVACY_ERR_500_MSG_ALTTEXT",
  errorTitle: "PRIVACY_ERR_500_MSG",
  errorMessage: "PRIVACY_ERR_502_MSG_EXP",
};

export const error502 = {
  // errorIcon: { IconError },
  errorId: "error_502",
  errorAltText: "PRIVACY_ERR_502_MSG_ALTTEXT",
  errorTitle: "PRIVACY_ERR_502_MSG",
  errorMessage: "PRIVACY_ERR_502_MSG_EXP",
};

export const error503 = {
  // errorIcon: { IconError },
  errorId: "error_503",
  errorAltText: "PRIVACY_ERR_503_MSG_ALTTEXT",
  errorTitle: "PRIVACY_ERR_503_MSG",
  errorMessage: "PRIVACY_ERR_503_MSG_EXP",
};

export const errorIE = {
  errorId: "error_ie",
  errorAltText: "",
  errorTitle: "PRIVACY_UNSUPPORTED_BROWSER_TITLE",
  errorMessage: "PRIVACY_UNSUPPORTED_BROWSER_DESC",
};
