import React, { Dispatch, ReactText, SetStateAction } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

import { Navigation } from "../../types/navs";
import { Svg } from "../__common/Svg/Svg";
import { Typography } from "../__common/Typography/Typography";
import { Container } from "../Grid/Grid";
import { useState } from "react";

import { useTranslation } from "react-i18next";

import triggerGAEvent from "../../utils/UtilsTriggerGAEvent";
import { EVENT_CATEGORY_HEADER } from "../../constants/analytics";

interface Props {
  navigation: Navigation;
  setShowSubMenu: Dispatch<SetStateAction<boolean>>;
  setActiveMenu: Dispatch<SetStateAction<ReactText>>;
  isActive?: boolean;
}

export const SubNav: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { navigation, isActive = false, setActiveMenu, setShowSubMenu } = props;
  const [activeRightContent, setActiveRightContent] = useState<any>();

  return (
    <div
      onMouseLeave={() => setShowSubMenu(false)}
      className={classNames("sub-nav", { active: isActive })}
    >
      <Container className="sub-nav__content">
        <div className="sub-nav__content__left">
          <div className="sub-links">
            {navigation.items && (
              <ul>
                {navigation.items.map((item) => {
                  return (
                    <li key={item.text}>
                      <NavLink
                        to={item.link || "#"}
                        onMouseEnter={() => {
                          setActiveRightContent(item);
                        }}
                        onMouseLeave={() => setActiveRightContent(undefined)}
                        onClick={() => {
                          triggerGAEvent(EVENT_CATEGORY_HEADER, item.gaAction);
                          setShowSubMenu(false);
                          setActiveMenu(1);
                        }}
                        activeClassName="active"
                        onBlur={() => {
                          if (item.id === 1) {
                            setShowSubMenu(false);
                          }
                        }}
                      >
                        {t(item.text)}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        {activeRightContent && (
          <div className="sub-nav__content__right" key={activeRightContent.id}>
            {activeRightContent.detail && (
              <>
                <div className="sub-nav__content__right__text">
                  <Typography variant="body1">
                    {t(activeRightContent.detail?.text)}
                  </Typography>
                </div>
                <div className="sub-nav__content__right__icon">
                  <Svg
                    icon={activeRightContent.detail?.svg}
                    width={236}
                    height={181}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};
