import { URLS } from "./urls";

// Google Analytics

// Environment host name
export const HOSTNAME_STG = "sdap.stg.samsungbdc.com";
export const HOSTNAME_PRD = "privacy.samsung.com";
export const HOSTNAME_DEV = "sdap.dev.samsungbdc.com";

// GA tracking ID
export const TRACKING_ID_STG = "UA-239978261-2";
export const TRACKING_ID_PRD = "UA-240620985-1";
export const TRACKING_ID_STG_GA4 = "G-T4CQX6JZ5L";
export const TRACKING_ID_PRD_GA4 = "G-RTSG8FQ6FW";
export const TRACKING_ID_DEV = "G-0C53RZ39PD";

export const VALID_ROUTES = [
  URLS.ROOT,
  URLS.HOME,
  URLS.LOGIN,
  URLS.ABOUT_OVERVIEW,
  URLS.ABOUT_GRPS,
  URLS.PRIVACY_POLICY,
  URLS.POLICY_OVERVIEW,
  URLS.COOKIE_POLICY,
  URLS.MYDATA,
  URLS.MYDATA_HISTORY,
  URLS.ERROR_400,
  URLS.ERROR_403,
  URLS.ERROR_404,
  URLS.ERROR_500,
  URLS.ERROR_502,
  URLS.ERROR_503,
];

// For common actions
export const EVENT_ACTION_MANAGE_COOKIES = "manage-cookies";
export const EVENT_REQUEST_DETAILS = "request-detail";
export const EVENT_CHANGE_LANGUAGE = "change-language";

// For homepage events tracking
export const EVENT_CATEGORY_HOME_PAGE = "homepage";
export const EVENT_HOME_ACTION_LEARN_HOW = "learn-how";
export const EVENT_HOME_ACTION_SEE_HOW = "see-how";
export const EVENT_HOME_ACTION_CLICK_HERE = "click-here";
export const EVENT_HOME_ACTION_MANAGE_DATA = "manage-data";
export const EVENT_HOME_ACTION_SECURITY_PRIVACY = "security-privacy";
export const EVENT_HOME_ACTION_GPS = "gps";

// For cookie notice events tracking
export const EVENT_CATEGORY_COOKIE_NOTICE = "cookie-notice";
export const EVENT_COOKIE_NOTICE_ACTION_ACCEPT_ALL = "accept-all";
export const EVENT_COOKIE_NOTICE_ACTION_CONTINUE_NO_ACCEPT =
  "continue-no-accept";
export const EVENT_COOKIE_NOTICE_ACTION_HAPPY_COOKIES = "happy-cookies";

// For footer events tracking
export const EVENT_CATEGORY_FOOTER = "footer";
export const EVENT_FOOTER_ACTION_COOKIE_POLICY = "cookie-policy";
export const EVENT_FOOTER_ACTION_PRIVACY_POLICY = "privacy-policy";

// For GPS events tracking
export const EVENT_CATEGORY_GPS = "gps";

/**
 * After selecting a region from the GPS page, GA4 will track the selected countries from the list
 *
 * Example:
 * - `list-<country_code>`
 *
 * [Documentation](https://confluence.sec.samsung.net/display/SDAPC/GA4+Events+Tracking)
 */
export const EVENT_GPS_ACTION_LIST = "list";
export const EVENT_GPS_ACTION_SDAP_SITE = "sdap-site";

// For GPS Popup events tracking
export const EVENT_CATEGORY_GPS_POPUP = "gps-popup";
export const EVENT_GPS_ACTION_LINK_VISIT = "link";
export const EVENT_GPS_ACTION_COUNTRY_VISIT = "visit";

//For Header events tracking
export const EVENT_CATEGORY_HEADER = "header";
export const EVENT_HEADER_ACTION_GPS = "gps";
export const EVENT_HEADER_ACTION_LOGIN = "login";
export const EVENT_HEADER_ACTION_LOGIN_CHINA = "login-china";
export const EVENT_HEADER_ACTION_LOGOUT = "logout";
export const EVENT_HEADER_ACTION_PP_OVERVIEW = "pp-overview";
export const EVENT_HEADER_ACTION_PRIVACY_POLICY = "privacy-policy";
export const EVENT_HEADER_ACTION_MY_DATA = "my-data";
export const EVENT_HEADER_ACTION_PRIVACY_LOGO = "privacy-logo";

/**
 * Special header event for tracking countries that click "My Data" in GNB
 *
 * Examples:
 * - `gnb-mydata-<country_code>-localsite` for countries with local site
 * - `gnb-mydata-<country_code>` for countries without local site
 *
 * [Documentation](https://confluence.sec.samsung.net/pages/viewpage.action?spaceKey=SDAPC&title=GA4+Country+Redirects+Tracking)
 */
export const EVENT_HEADER_ACTION_MY_DATA_COUNTRY = "gnb-mydata";

/**
 * Special drawer event for tracking countries that click "My Data" in mobile drawer
 *
 * Examples:
 * - `drawer-mydata-<country_code>-localsite` for countries with local site
 * - `drawer-mydata-<country_code>` for countries without local site
 *
 * [Documentation](https://confluence.sec.samsung.net/pages/viewpage.action?spaceKey=SDAPC&title=GA4+Country+Redirects+Tracking)
 */
export const EVENT_DRAWER_ACTION_MY_DATA_COUNTRY = "drawer-mydata";

// For My Data History events tracking
export const EVENT_CATEGORY_MY_DATA_HISTORY = "my-data-history";
export const EVENT_MY_DATA_HISTORY = "my-data-history";
export const EVENT_MY_DATA_HISTORY_ACTION_ALL_ALL = "all-all";
export const EVENT_MY_DATA_HISTORY_ACTION_ALL_REQUESTED = "all-requested";
export const EVENT_MY_DATA_HISTORY_ACTION_ALL_PROCESSING = "all-processing";
export const EVENT_MY_DATA_HISTORY_ACTION_ALL_COMPLETED = "all-completed";
export const EVENT_MY_DATA_HISTORY_ACTION_ALL_EXPIRED = "all-expired";
export const EVENT_MY_DATA_HISTORY_ACTION_ALL_CANCELED = "all-canceled";
export const EVENT_MY_DATA_HISTORY_ACTION_DOWNLOAD_ALL = "download-all";
export const EVENT_MY_DATA_HISTORY_ACTION_DOWNLOAD_REQUESTED =
  "download-requested";
export const EVENT_MY_DATA_HISTORY_ACTION_DOWNLOAD_PROCESSING =
  "download-processing";
export const EVENT_MY_DATA_HISTORY_ACTION_DOWNLOAD_COMPLETED =
  "download-completed";
export const EVENT_MY_DATA_HISTORY_ACTION_DOWNLOAD_EXPIRED = "download-expired";
export const EVENT_MY_DATA_HISTORY_ACTION_DOWNLOAD_CANCELED =
  "download-canceled";
export const EVENT_MY_DATA_HISTORY_ACTION_DELETION_ALL = "deletion-all";
export const EVENT_MY_DATA_HISTORY_ACTION_DELETION_REQUESTED =
  "deletion-requested";
export const EVENT_MY_DATA_HISTORY_ACTION_DELETION_PROCESSING =
  "deletion-processing";
export const EVENT_MY_DATA_HISTORY_ACTION_DELETION_COMPLETED =
  "deletion-completed";
export const EVENT_MY_DATA_HISTORY_ACTION_DELETION_EXPIRED = "deletion-expired";
export const EVENT_MY_DATA_HISTORY_ACTION_DELETION_CANCELED =
  "deletion-canceled";
export const EVENT_MY_DATA_HISTORY_ACTION_OPTOUT_ALL = "optout-all";
export const EVENT_MY_DATA_HISTORY_ACTION_OPTOUT_REQUESTED = "optout-requested";
export const EVENT_MY_DATA_HISTORY_ACTION_OPTOUT_PROCESSING =
  "optout-processing";
export const EVENT_MY_DATA_HISTORY_ACTION_OPTOUT_COMPLETED = "optout-completed";
export const EVENT_MY_DATA_HISTORY_ACTION_OPTOUT_EXPIRED = "optout-expired";
export const EVENT_MY_DATA_HISTORY_ACTION_OPTOUT_CANCELED = "optout-canceled";

export const EVENT_MY_DATA_HISTORY_ACTION_ALL: { [key: string]: string } = {
  L: "all-all",
  W: "all-requested",
  T: "all-processing",
  C: "all-completed",
  E: "all-expired",
  S: "all-canceled",
};

export const EVENT_MY_DATA_HISTORY_ACTION_DOWNLOAD: { [key: string]: string } =
  {
    L: "download-all",
    W: "download-requested",
    T: "download-processing",
    C: "download-completed",
    E: "download-expired",
    S: "download-canceled",
  };

export const EVENT_MY_DATA_HISTORY_ACTION_DELETION: { [key: string]: string } =
  {
    L: "deletion-all",
    W: "deletion-requested",
    T: "deletion-processing",
    C: "deletion-completed",
    E: "deletion-expired",
    S: "deletion-canceled",
  };
export const EVENT_MY_DATA_HISTORY_ACTION_OPTOUT: { [key: string]: string } = {
  L: "optout-all",
  W: "optout-requested",
  T: "optout-processing",
  C: "optout-completed",
  E: "optout-expired",
  S: "optout-canceled",
};

// For My Data events tracking
export const EVENT_CATEGORY_MY_DATA = "my-data";
export const EVENT_MY_DATA_ACTION_MORE_INFORMATION = "more-information";
export const EVENT_MY_DATA_ACTION_SUMMARY_VIEW_ALL = "summary-view-all";
export const EVENT_MY_DATA_ACTION_REQUEST_CONFIRMATION: {
  [key: string]: string;
} = {
  A: "request-confirmation-download",
  E: "request-confirmation-delete",
  U: "request-confirmation-unsubscribe",
};

// For Privacy Policy events tracking
export const EVENT_CATEGORY_PRIVACY_POLICY = "privacy-policy";
export const EVENT_ACTION_PP_CHANGE_VERSION = "change-version";
export const EVENT_ACTION_PP_DOWNLOAD_PRIVACY_POLICY = "download-pp";

// For Request Details events tracking
export const EVENT_CATEGORY_REQUEST_DETAILS = "request-details";
export const EVENT_ACTION_DETAILS_CANCEL_REQUEST = "cancel-request";
export const EVENT_ACTION_DETAILS_DOWNLOAD_FILE = "download-file";
export const EVENT_ACTION_DETAILS_SEND_FILE_PASSWORD = "send-file-password";

// Actions used for download file, and view/send file password event category
export const EVENT_ACTION_SERVICES = {
  CD30001: "Samsung Health",
  CD30002: "PENUP",
  CD30003: "Samsung Account",
  CD30004: "Samsung Social",
  CD30005: "Bixby Voice",
  CD30006: "Galaxy Apps",
  CD30007: "Samsung Themes",
  CD30008: "Samsung Pay",
  CD30009: "Samsung Members",
  CD30010: "SmartThings",
  CD30011: "Samsung Cloud",
  CD30012: "Secure Wi-Fi",
  CD30013: "Call & text on other devices",
  CD30014: "Samsung Pass",
  CD30015: "Find My Mobile",
  CD30017: "Samsung Wearable",
  CD30018: "Customization service",
  CD30019: "Samsung VR",
  CD30020: "Samsung Blockchain Wallet",
  dplug: "Smart Appliance",
  familyhub: "Family Hub",
  CD20003: "Smart Appliance",
  CD20002: "Family Hub",
  hrm: "HRM",
  VDSCCD00001: "Smart TV",
  CD10001: "Smart Hub",
  CD30027: "Samsung Free",
  CD30016: "Content & Services Customer Support",
  CD30021: "Samsung Visit In",
  CD30025: "Samsung Global Goals",
  CD30026: "Samsung Rewards",
  CD30029: "Walkie-Talkie",
  CD30024: "Samsung TV Plus (Mobile/Web)",
  CD30030: "Health Platform",
  CD30023: "Game Launcher",
  CD10004: "Chat Together (Beta)",
  CD10002: "Samsung.com (Personalize my TV)",
};

// For Hub Landing page tracking
export const EVENT_CATEGORY_LANDING_PAGE = "landing-page";

// For login options
export const EVENT_CATEGORY_LOGIN_OPTIONS = "login-options";

/**
 * Triggered when the user clicks the login via SA button
 *
 * *NOTE:* User is still not logged in when the button is clicked
 */
export const EVENT_ACTION_LOGIN_VIA_SA = "login-via-sa";

/**
 * Triggered when the user clicks the login via email
 *
 * *NOTE:* User is still not logged in when the button is clicked
 */
export const EVENT_ACTION_LOGIN_VIA_EMAIL = "login-via-email";

/**
 * Triggered when the user clicks the verify OTP button
 *
 * *NOTE:* This event is triggered regardless whether the OTP is valid or not
 */
export const EVENT_ACTION_VERIFY_OTP = "verify-otp";

/**
 * Triggered when user resends OTP
 */
export const EVENT_ACTION_RESEND_OTP = "resend-otp";

/**
 * Tracks countries that redirects to their local site
 *
 * Example:
 * - `hub-local-privacy-<country_code>`
 *
 * [Documentation](https://confluence.sec.samsung.net/pages/viewpage.action?spaceKey=SDAPC&title=GA4+Country+Redirects+Tracking)
 */
export const EVENT_LANDING_PAGE_LOCAL_PRIVACY = "hub-local-privacy";

/**
 * Track countries that are selected in `LocalPrivacySiteModal` component
 *
 * Example:
 * - `hub-change-<country_code>-localsite` for countries with local site
 * - `hub-change-<country_code` for countries without local site
 *
 * [Documentation](https://confluence.sec.samsung.net/pages/viewpage.action?spaceKey=SDAPC&title=GA4+Country+Redirects+Tracking)
 */
export const EVENT_LANDING_PAGE_CHANGE_LOCALSITE = "hub-change";

/**
 * Tracks countries *(w/o local site only)* that click "Go to My Data" in the landing page (Scenario 2)
 *
 * Example:
 * - `hub-mydata-<country_code>`
 *
 * [Documentation](https://confluence.sec.samsung.net/pages/viewpage.action?spaceKey=SDAPC&title=GA4+Country+Redirects+Tracking)
 */
export const EVENT_LANDING_PAGE_MYDATA = "hub-mydata";

/**
 * Tracks countries *(w/ local site only)* that click `Go to Request History` in the landing page (Scenario 3)
 *
 * Example:
 * - `hub-requesthistory-<country_code>`
 *
 * [Documentation](https://confluence.sec.samsung.net/pages/viewpage.action?spaceKey=SDAPC&title=GA4+Country+Redirects+Tracking)
 */
export const EVENT_LANDING_PAGE_REQUEST_HISTORY = "hub-requesthistory";
