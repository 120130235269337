import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./VersionHistoryModal.scss";
import Modal from "../__common/Modal/Modal";
import { CBType, Checkbox } from "../__common/_controls/Checkbox/Checkbox";
import { VersionHistories } from "../../data/version-history";
import { VersionHistory } from "../../types/version-history";
import classNames from "classnames";
import { UtilsKeyboardAccessibility } from "../../utils/UtilsKeyboardAccessibility";

interface Props {
  show?: boolean;
  selectedHistory: VersionHistory;
  onHide?: () => void;
  onHisotrySelect: (h: VersionHistory) => void;
}

export const VersionHistoryModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { show = false, selectedHistory, onHide, onHisotrySelect } = props;

  const handleOnHide = () => {
    if (onHide) {
      onHide();
    }
  };

  useEffect(() => {
    if (show) {
      UtilsKeyboardAccessibility.setFocus("version-history-modal");
    }
  }, [show]);

  useEffect(() => {
    UtilsKeyboardAccessibility.addTabEventListener(
      "last-version",
      "version-history-modal"
    );

    return () => {
      UtilsKeyboardAccessibility.removeTabEventListener(
        "last-version",
        "version-history-modal"
      );
    };
  }, []);

  return (
    <Modal
      show={show}
      wrapperClassName="version-history-modal"
      id="version-history-modal"
      tabIndex={0}
    >
      <Modal.Header onHide={handleOnHide}>
        <Modal.Title>
          {t("PRIVACY_POLICY_GPP_VERSION_HISTORY_POPUP_TITLE")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body isNoFooter>
        {VersionHistories.map((history, index, { length }) => {
          const wrapClass = classNames("version-history-modal__item", {
            active: selectedHistory.date === history.date,
          });
          const isLast = length - 1 === index;

          return (
            <div
              className={wrapClass}
              key={history.date}
              id={isLast ? "last-version" : undefined}
            >
              <Checkbox
                title={history.displayDate}
                name="checkbox-withoutSelectBg"
                type={CBType.withoutSelectBg}
                className="language-modal__checkbox"
                checked={selectedHistory.date === history.date}
                onClick={() => onHisotrySelect(history)}
                onKeyPress={() => onHisotrySelect(history)}
              />
            </div>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};
