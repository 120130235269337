import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../configs/routes";
import { useCookie } from "../../hooks/cookie-hooks";
import { ApplicationState } from "../../store";
import { UserState } from "../../store/reducers/user-reducer";
import Alert from "../__common/Alert/Alert";
import { Svg, SVG_ICONS } from "../__common/Svg/Svg";
import { Typography } from "../__common/Typography/Typography";
import { Button } from "../__common/_controls";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

import "./CookieModal.scss";
import { ManageCookieModal } from "./ManageCookie";

import triggerGAEvent from "../../utils/UtilsTriggerGAEvent";
import {
  EVENT_CATEGORY_COOKIE_NOTICE,
  EVENT_COOKIE_NOTICE_ACTION_ACCEPT_ALL,
  EVENT_COOKIE_NOTICE_ACTION_CONTINUE_NO_ACCEPT,
  EVENT_COOKIE_NOTICE_ACTION_HAPPY_COOKIES,
  EVENT_ACTION_MANAGE_COOKIES,
} from "../../constants/analytics";
import { UtilsKeyboardAccessibility } from "../../utils/UtilsKeyboardAccessibility";
import FocusTrapping from "../__hoc/FocusTrapping";

export const NonEuCookieAlert: React.FC = () => {
  const { isCookieNoticeOpen, setCookiePreference, setCookieNoticeModalOpen } =
    useCookie();
  const [isNonEuShow, setNonEuShow] = useState(isCookieNoticeOpen);
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!userState.userRegion.isFromEurope) {
      setNonEuShow(isCookieNoticeOpen);
    }
  }, [isCookieNoticeOpen, userState.userRegion.isFromEurope]);

  useEffect(() => {
    if (isNonEuShow) {
      UtilsKeyboardAccessibility.setFocus("cookie-noneu");
    }
  }, [isNonEuShow]);

  useEffect(() => {
    UtilsKeyboardAccessibility.addTabEventListener(
      "cookie-noneu-title",
      "pseudo-noneu"
    );
    UtilsKeyboardAccessibility.addTabEventListener(
      "cookie-accept-button",
      "cookie-noneu"
    );

    return () => {
      UtilsKeyboardAccessibility.removeTabEventListener(
        "cookie-noneu-title",
        "pseudo-noneu"
      );
      UtilsKeyboardAccessibility.removeTabEventListener(
        "cookie-accept-button",
        "cookie-noneu"
      );
    };
  }, []);

  return (
    <div className="cookie-noneu" id="cookie-noneu" tabIndex={0}>
      <Alert
        show={isNonEuShow}
        title={t("PRIVACY_COOKIE_NOTICE_TITLE")}
        ttsButtonId="cookie-noneu-title"
      >
        <div className="cookie-noneu__text">
          <Typography variant="body2">
            <Trans
              i18nKey="PRIVACY_COOKIE_NOTICE_DEFAULT"
              components={{
                a: (
                  <Link
                    data-testid="cookie-noneu-text-link"
                    to={ROUTES.PRIVACY_POLICY}
                    className="cookie-noneu__text__link"
                    onClick={() => {
                      setNonEuShow(false);
                      setCookieNoticeModalOpen(false);
                    }}
                  />
                ),
              }}
            />
          </Typography>
        </div>
        <Alert.Footer>
          <Button
            title={t("PRIVACY_COOKIE_NOTICE_DEFAULT_BTN")}
            className="cookie-noneu__accept-button"
            onClick={() => {
              triggerGAEvent(
                EVENT_CATEGORY_COOKIE_NOTICE,
                EVENT_COOKIE_NOTICE_ACTION_ACCEPT_ALL
              );
              setCookiePreference(true, true);
              document.getElementById("header")?.focus();
            }}
            testId="cookie-accept-button"
            id="cookie-accept-button"
          />
          <div id="pseudo-noneu" tabIndex={0}></div>
        </Alert.Footer>
      </Alert>
    </div>
  );
};

export const EuCookieAlert: React.FC = () => {
  const {
    isCookieNoticeOpen,
    isManageCookieOpen,
    setCookiePreference,
    setManageCookieOpen,
    setCookieNoticeOpen,
    setCookieNoticeModalOpen,
  } = useCookie();
  const [isShow, setEuShow] = React.useState(isCookieNoticeOpen);
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (userState.userRegion.isFromEurope) {
      setEuShow(isCookieNoticeOpen);
    }
  }, [isCookieNoticeOpen, userState.userRegion.isFromEurope]);

  useEffect(() => {
    if (isShow) {
      UtilsKeyboardAccessibility.setFocus("cookie-eu");
    }
  }, [isShow]);

  useEffect(() => {
    UtilsKeyboardAccessibility.addTabEventListener(
      "accept-cookies-button",
      "cookie-eu"
    );

    return () => {
      UtilsKeyboardAccessibility.removeTabEventListener(
        "accept-cookies-button",
        "cookie-eu"
      );
    };
  }, []);

  const closeComponent = (viewType: string) => (
    <button
      className={viewType}
      data-testid="close-btn"
      onClick={() => {
        triggerGAEvent(
          EVENT_CATEGORY_COOKIE_NOTICE,
          EVENT_COOKIE_NOTICE_ACTION_CONTINUE_NO_ACCEPT
        );
        setCookiePreference(false, false);
      }}
    >
      <Typography variant="body3" className="cookie-eu__close__text">
        {t("PRIVACY_COOKIE_NOTICE_EU_BTN_CONTINUE_WITHOUT_ACCEPT")}
        <Svg icon={SVG_ICONS.CLOSE_SMALL} width={12} height={12} />
      </Typography>
    </button>
  );
  return (
    <>
      <div className="cookie-eu" id="cookie-eu" tabIndex={0}>
        <Alert
          show={isShow}
          title={t("PRIVACY_COOKIE_NOTICE_TITLE")}
          customClose={closeComponent("cookie-eu__close")}
        >
          <div className="cookie-eu__text">
            <Typography variant="body2">
              <Trans
                i18nKey="PRIVACY_COOKIE_NOTICE_EU"
                components={{
                  a1: (
                    <Link
                      data-testid="a1-button"
                      to={ROUTES.PRIVACY_POLICY}
                      className="cookie-noneu__text__link"
                      onClick={() => {
                        setEuShow(false);
                        setCookieNoticeModalOpen(false);
                      }}
                    />
                  ),
                  a2: (
                    <Link
                      data-testid="a2-button"
                      to={ROUTES.COOKIE_POLICY}
                      className="cookie-noneu__text__link"
                      onClick={() => {
                        setEuShow(false);
                        setCookieNoticeModalOpen(false);
                      }}
                    />
                  ),
                }}
              />
            </Typography>
          </div>
          {closeComponent("cookie-eu__close-in-body")}
          <Alert.Footer>
            <div className="button-container">
              <Button
                type="secondary"
                testId="close-modal-btn"
                title={t("PRIVACY_COOKIE_NOTICE_EU_BTN_MANAGE_COOKIES")}
                onClick={() => {
                  triggerGAEvent(
                    EVENT_CATEGORY_COOKIE_NOTICE,
                    EVENT_ACTION_MANAGE_COOKIES
                  );
                  setManageCookieOpen(true);
                  setCookieNoticeOpen(false);
                  setCookieNoticeModalOpen(false);
                }}
              ></Button>
            </div>
            <div className="button-container">
              <Button
                testId="cookie-notice-eu-btn"
                title={t("PRIVACY_COOKIE_NOTICE_EU_BTN_ACCEPT")}
                onClick={() => {
                  setCookiePreference(true, true);
                  triggerGAEvent(
                    EVENT_CATEGORY_COOKIE_NOTICE,
                    EVENT_COOKIE_NOTICE_ACTION_HAPPY_COOKIES
                  );
                }}
                id="accept-cookies-button"
              ></Button>
            </div>
          </Alert.Footer>
        </Alert>
      </div>
      <FocusTrapping
        isActive={isManageCookieOpen}
        elements="svg, button, div.title2 , div.modal-body, div.manage-cookie"
      >
        <ManageCookieModal isOpen={isManageCookieOpen} />
      </FocusTrapping>
    </>
  );
};

export * from "./ManageCookie";
