import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { LayoutState } from "../store/reducers/layout-reducer";
import { UserState } from "../store/reducers/user-reducer";
import { useEffect, useState } from "react";
import {
  CountriesWithLocalPrivacy,
  EuropeCountriesExceptTurkey,
  countriesWithNoSiteAccess,
} from "../data/landing-page";
import triggerGAEvent from "../utils/UtilsTriggerGAEvent";
import {
  EVENT_CATEGORY_HEADER,
  EVENT_CATEGORY_LANDING_PAGE,
  EVENT_CATEGORY_MY_DATA_HISTORY,
  EVENT_DRAWER_ACTION_MY_DATA_COUNTRY,
  EVENT_HEADER_ACTION_MY_DATA_COUNTRY,
  EVENT_LANDING_PAGE_MYDATA,
  EVENT_LANDING_PAGE_REQUEST_HISTORY,
  EVENT_MY_DATA_HISTORY,
} from "../constants/analytics";
import { useWindowSize } from "./window-hooks";

export const useHub = () => {
  const { selectedCountry } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { userCountryCode } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  const [hasLocalSite, setHasLocalSite] = useState<boolean>(false);
  const [localSite, setLocalSite] = useState<string | null>("");
  const [isEuropeCountry, setIsEuropeCountry] = useState<boolean>(false);
  const [isCountryNotAllowed, setisCountryNotAllowed] =
    useState<boolean>(false);
  const [isCountryUserGeoipNotAllowed, setCountryUserGeoipNotAllowed] =
    useState<boolean>(false);

  const { isMobile } = useWindowSize();

  useEffect(() => {
    const countryCode = selectedCountry.code || userCountryCode;

    const hasCountryLocalSite = CountriesWithLocalPrivacy.some(
      (country) => country.code === countryCode
    );

    const isEuropeCountryExceptTurkey = EuropeCountriesExceptTurkey.some(
      (country) => country.code === countryCode
    );
    const isNotAllowed = countriesWithNoSiteAccess.some(
      (countryCodes) => countryCodes === selectedCountry.code
    );
    const isGeoipNotAllowed = countriesWithNoSiteAccess.some(
      (countryCodes) => countryCodes === userCountryCode
    );
    if (hasCountryLocalSite && !isEuropeCountryExceptTurkey) {
      const localSiteLink = CountriesWithLocalPrivacy.filter(
        (country) => country.code === countryCode
      )[0].link;
      setLocalSite(localSiteLink);
    }

    setCountryUserGeoipNotAllowed(isGeoipNotAllowed);
    setHasLocalSite(hasCountryLocalSite);
    setIsEuropeCountry(isEuropeCountryExceptTurkey);
    setisCountryNotAllowed(isNotAllowed);
  }, [selectedCountry, userCountryCode]);

  const handleLocalSiteRedirect = () => {
    if (localSite) {
      window.open(localSite, "_self");
    }
  };

  /**
   * Tracks event in Google Analytics when user clicks `My Data` in GNB/Drawer
   *
   * Triggers the following for GNB:
   * - `gnb-mydata-<country_code>-localsite` for countries with local site
   * - `gnb-mydata-<country_code>` for countries without local site
   *
   * Triggers the following for mobile drawer:
   * - `drawer-mydata-<country_code>-localsite` for countries with local site
   * - `drawer-mydata-<country_code>` for countries without local site
   */
  const handleGAMyDataRedirectNav = () => {
    const countryCode = selectedCountry.code || userCountryCode;
    const actionType = !isMobile
      ? EVENT_HEADER_ACTION_MY_DATA_COUNTRY
      : EVENT_DRAWER_ACTION_MY_DATA_COUNTRY;
    const localSiteMarker =
      hasLocalSite && !isEuropeCountry ? "-localsite" : "";

    triggerGAEvent(
      EVENT_CATEGORY_HEADER,
      `${actionType}-${countryCode}${localSiteMarker}`
    );
  };

  /**
   * Tracks countries *(w/ local site only)* that click `Go to Request History` in the landing page (Scenario 3)
   *
   * Example:
   * - `hub-requesthistory-<country_code>`
   */
  const handleGARequestHistoryRedirectHub = () => {
    const countryCode = selectedCountry.code || userCountryCode;

    triggerGAEvent(
      EVENT_CATEGORY_LANDING_PAGE,
      `${EVENT_LANDING_PAGE_REQUEST_HISTORY}-${countryCode}`
    );
  };

  /**
   * Track countries that go to request history page from the my data page
   *
   * Example:
   * - `my-data-history-<country_code>
   */
  const handleGARequestHistoryRedirect = () => {
    const countryCode = selectedCountry.code || userCountryCode;

    triggerGAEvent(
      EVENT_CATEGORY_MY_DATA_HISTORY,
      `${EVENT_MY_DATA_HISTORY}-${countryCode}`
    );
  };

  /**
   * Tracks event in Google Analytics when user clicks `Go to My Data` in landing page scenario 2
   *
   * Example:
   * - `hub-mydata-<country_code>`
   */
  const handleGAMyDataRedirectHub = () => {
    const countryCode = selectedCountry.code || userCountryCode;

    triggerGAEvent(
      EVENT_CATEGORY_LANDING_PAGE,
      `${EVENT_LANDING_PAGE_MYDATA}-${countryCode}`
    );
  };

  return {
    // State
    isCountryUserGeoipNotAllowed,
    hasLocalSite,
    isEuropeCountry,
    localSite,
    isCountryNotAllowed,

    // Functions
    handleLocalSiteRedirect,
    handleGAMyDataRedirectNav,
    handleGARequestHistoryRedirectHub,
    handleGARequestHistoryRedirect,
    handleGAMyDataRedirectHub,
  };
};
