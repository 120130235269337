import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./ScrollToTop.scss";
import { Svg, SVG_ICONS } from "../Svg/Svg";
import { useWindowSize } from "../../../hooks/window-hooks";
import { Container } from "../../Grid/Grid";

interface Props {
  testId?: string;
}

export const ScrollToTop: React.FC<Props> = ({ testId }) => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [isFooterReached, setFooterReached] = useState(false);
  const { isMobile } = useWindowSize();

  const wrapClass = classNames("scroll", {
    open: !!showTopBtn,
    absolute: isFooterReached,
  });

  useEffect(() => {
    const onScroll = () => {
      const pixels = isMobile ? 201 : 124;

      if (
        document.getElementById("main-body")!.scrollTop >
        document.getElementById("main-body")!.clientHeight / 2
      ) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }

      if (
        document.getElementById("main-body")!.scrollTop +
          document.getElementById("main-body")!.clientHeight +
          pixels >=
        document.getElementById("main-body")!.scrollHeight
      ) {
        setFooterReached(true);
      } else {
        setFooterReached(false);
      }
    };

    const onScrollMobile = () => {
      if (window.scrollY > document.body.scrollHeight / 2) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }

      if (
        window.scrollY + window.innerHeight + 149 >=
        document.body.scrollHeight
      ) {
        setFooterReached(true);
      } else {
        setFooterReached(false);
      }
    };

    document.getElementById("main-body")!.addEventListener("scroll", onScroll);

    if (!isMobile) {
      document
        .getElementById("main-body")!
        .addEventListener("scroll", onScroll);
    } else {
      window.addEventListener("scroll", onScrollMobile);
    }

    return () => {
      document
        .getElementById("main-body")!
        .removeEventListener("scroll", onScroll);

      window.removeEventListener("scroll", onScrollMobile);
    };
  }, [isMobile]);

  const handleOnClick = () => {
    document.getElementById("main-body")!.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container className="scroll-container">
      <div className="scroll-wrapper" data-testid={testId}>
        <div className={wrapClass}>
          <button
            className="scroll__button"
            onClick={handleOnClick}
            aria-label="scroll-to-top-btn"
          >
            <Svg icon={SVG_ICONS.ARROW_UP} width={24} height={24} />
          </button>
        </div>
      </div>
    </Container>
  );
};
