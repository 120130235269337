import { CustomAction } from "../index";
import { USER_ACTION_TYPES } from "../actions-types";
import { User } from "../../types/user";

export const actionSetUser = (data: User): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER,
    data,
  };
};

export const actionSetUserCountry = (data: string): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_COUNTRY_CODE,
    data,
  };
};

export const actionSetUserRegion = (data: {
  isFromUS: boolean;
  isFromEurope: boolean;
}): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_REGION,
    data,
  };
};

export const actionLogout = (): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_LOGOUT,
  };
};

export const actionSetGeoIpLoading = (data: boolean): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_GEOIP_LOADING,
  };
};

export const actionSetSACountry = (data: {
  isSAFromAmerica: boolean;
  isSAFromUSA: boolean;
  isSAFromEurope: boolean;
}): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_SA_COUNTRY,
    data,
  };
};

export const actionSetGetDataUserLoading = (data: boolean): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_DATA_LOADING,
    data,
  };
};

export const actionSetRestriction = (data: boolean): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_RESTRICTION_FLAG,
    data,
  };
};

export const actionSetGetSurveyResponsePending = (data: boolean): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_SURVEY_RESPONSE_PENDING,
    data,
  };
};

export const actionSetUserAnsweredSurvey = (data: boolean): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_ANSWERED_SURVEY,
    data,
  };
};

export const actionSetSubmitSurveyPending = (data: boolean): CustomAction => {
  return {
    type: USER_ACTION_TYPES.SET_USER_SUBMIT_SURVEY_PENDING,
    data,
  };
};
