import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Container } from "../../../../components/Grid/Grid";
import { Typography } from "../../../../components/__common/Typography/Typography";
import { ROUTES } from "../../../../configs/routes";

import { useMyDataHooks } from "../../../../hooks/mydata-hooks";
import { ApplicationState } from "../../../../store";
import { MyDataState } from "../../../../store/reducers/mydata-reducer";
import { RequestDetailsModal } from "../RequestDetailsModal/RequestDetailsModal";
import { RequestDetails } from "../Sections/RequestDetails/RequestDetails";
import Loading from "../../../../assets/images/gif/loading_transparent.gif";
import LoadingHC from "../../../../assets/images/gif/loading_transparent_HC.gif";

import "./History.scss";
import { Image } from "../../../../components/__common/Image";
import { Button, TTSButton } from "../../../../components/__common/_controls";
import { HistoryFilters } from "./HistoryFilters";
import { URLS } from "../../../../constants/urls";
import {
  CLOSE_CONFIRMATION_MODAL,
  MYDATA_HISTORY_CONFIRM_TOKEN,
  RESTRICTED,
} from "../../../../constants/storage";
import { UtilsRequest } from "../../../../utils/UtilsRequest";
import { UtilsUser } from "../../../../utils/UtilsUser";
import { LayoutState } from "../../../../store/reducers/layout-reducer";
import { UserState } from "../../../../store/reducers/user-reducer";
import {
  SummaryRequestDetails,
  SummarySubRequestDetails,
} from "../../../../types/mydata";
import { ServiceRequestToastError } from "../ServiceRequestToastError/ServiceRequestToastError";

export const History = () => {
  const { t } = useTranslation();
  const routeHistory = useHistory();
  const params: any = useParams();
  const {
    loadMyDataHistory,
    confirmRequest,
    clearConfirmRequestData,
    resetServiceRequestErrorToast,

  } = useMyDataHooks();
  const myDataState = useSelector<ApplicationState, MyDataState>(
    (state) => state.myData
  );
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  // const [surveyModalOpen, setSurveyModalOpen] = useState(false);
  const {
    history,
    isHistoryLoaded,
    isHistoryMoreLoading,
    hasMoreHistory,
    historyReqBody,
    requestNumber,
    isRequestPending,
  } = myDataState;
  const { isHighContrast } = layoutState;
  // const showSurveyCookie = Cookie.get(SHOW_SURVEY);
  const location: any = useLocation();
  const urlString = location.search;
  const confirmToken = urlString.match(/confirm_token=([^&]*)/);
  const request = UtilsRequest.getRequestInfo();
  const [openRequestDetails, setOpenRequestDetails] = useState<boolean>(false);
  const [displayToast, setDisplayToast] = useState<boolean>(false);

  useEffect(
    () => {
      // trigger the confirmation modal to close
      sessionStorage.setItem(CLOSE_CONFIRMATION_MODAL, "true");
      if (sessionStorage.getItem(RESTRICTED) === "true") {
        routeHistory.replace(ROUTES.MYDATA);
      }

      if (confirmToken) {
        sessionStorage.setItem(MYDATA_HISTORY_CONFIRM_TOKEN, confirmToken[1]);
        routeHistory.replace(ROUTES.MYDATA_HISTORY);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const currentURL = window.location.pathname;
      if (currentURL.includes(URLS.OLD_MYDATA)) {
        const requestNumber = currentURL.split("/")[3];
        if (requestNumber) {
          routeHistory.replace(`${URLS.MYDATA_HISTORY}/${requestNumber}`);
        }
      } else if (!isHistoryLoaded && userState.userData.countryCode !== "") {
        loadMyDataHistory(historyReqBody);
      }
    },
    // eslint-disable-next-line
    [layoutState.languageTrans, userState.userData.countryCode]
  );

  // useEffect(() => {
  //   if (Cookie.get(SHOW_SURVEY) === "true") {
  //     setSurveyModalOpen(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showSurveyCookie]);

  const handleOnCancelRequest = () => {
    loadMyDataHistory(historyReqBody);
  };

  const handleViewMoreList = () => {
    loadMyDataHistory(
      {
        ...historyReqBody,
        offset: history.next_offset,
      },
      true
    );
  };
  const handleErrorToastClose = () => {
    if (myDataState.serviceRequestToastError.isError|| myDataState.serviceRequestToastError.hasRendered) {
      resetServiceRequestErrorToast();
      setDisplayToast(false);
    }
  };

  const lastHistoryItemRef = useRef<HTMLDivElement>(null);
  const isBottom = (ref: React.RefObject<HTMLDivElement>): boolean => {
    if (!ref.current) return false;

    return ref.current.getBoundingClientRect().bottom <= window.innerHeight;
  };

  //Redirect After Confirm password
  useEffect(() => {
    if (requestNumber !== "" && !isRequestPending) {
      routeHistory.replace(`${ROUTES.MYDATA_HISTORY}/${requestNumber}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestNumber, isRequestPending]);

  useEffect(() => {
    if (layoutState.countryCode !== "") {
      if (request && UtilsUser.isUserLoggedIn() && !isRequestPending) {
        if (sessionStorage.getItem(MYDATA_HISTORY_CONFIRM_TOKEN)) {
          const historyConfirmToken = String(
            sessionStorage.getItem(MYDATA_HISTORY_CONFIRM_TOKEN)
          );
          //CHECK
          setOpenRequestDetails(true);
          confirmRequest(
            request.services,
            request.requestType,
            historyConfirmToken
          );
          sessionStorage.removeItem(MYDATA_HISTORY_CONFIRM_TOKEN);
        }
      } else {
        UtilsRequest.removeRequestInfo();
      }
    }
    // eslint-disable-next-line
  }, [layoutState.countryCode]);

  useEffect(
    () => {
      const onScroll = () => {
        if (
          !isHistoryMoreLoading &&
          hasMoreHistory &&
          isBottom(lastHistoryItemRef)
        ) {
          handleViewMoreList();
        }
      };

      document.querySelector("main")?.addEventListener("scroll", onScroll);
      return () =>
        document.querySelector("main")?.removeEventListener("scroll", onScroll);
    },
    // eslint-disable-next-line
    [isHistoryMoreLoading, hasMoreHistory, historyReqBody]
  );

  useEffect(() => {
    if (myDataState.serviceRequestToastError.hasRendered) {
      setDisplayToast(true);
    }

    return () => {
      setDisplayToast(false);
      handleErrorToastClose();
    }
  },[myDataState.serviceRequestToastError.hasRendered])

  return (
    <>
      {(openRequestDetails || !!params.requestNumber) && (
        <RequestDetailsModal
          show={openRequestDetails || !!params.requestNumber}
          requestNumber={params.requestNumber}
          onHide={() => {
            clearConfirmRequestData();
            routeHistory.replace(ROUTES.MYDATA_HISTORY);
          }}
          onCompleteCancel={handleOnCancelRequest}
        />
      )}
      {/* {!params.requestNumber && <SurveyModal isOpen={surveyModalOpen} />} */}
      
      {/* ERROR TOAST POPUP if user failed to request services */}
      {displayToast &&(
          <ServiceRequestToastError
            isSAUnverified={myDataState.serviceRequestToastError.isSAUnverifiedError}
            show={displayToast}
            onClose={handleErrorToastClose}
          />
        )}
      <div className="mydata-personal-info">
        <div className="mydata">
          <div className="mydata__title-section">
            <Typography variant="h2">
              {t("PRIVACY_MYREQUEST_PAGE_TITLE")}
              <TTSButton
                value={
                  t("PRIVACY_MYREQUEST_PAGE_TITLE") +
                  ". " +
                  t("PRIVACY_MYREQUEST_PAGE_DESC")
                }
              />
            </Typography>
            <Typography variant="body1" className="mydata__title-section__sub">
              {t("PRIVACY_MYREQUEST_PAGE_DESC")}
            </Typography>
          </div>
        </div>
        <HistoryFilters
          loadMyDataHistory={loadMyDataHistory}
          historyReqBody={historyReqBody}
        />
        <Container>
          <div
            className="mydata-personal-info__services-container"
            ref={lastHistoryItemRef}
          >
            <ul>
              {history &&
                history.request_list.map(
                  (
                    request: SummaryRequestDetails<SummarySubRequestDetails>
                  ) => {
                    return (
                      <li key={request.request_number}>
                        <RequestDetails
                          request={request}
                          route={ROUTES.MYDATA_HISTORY}
                        />
                      </li>
                    );
                  }
                )}
              {isHistoryMoreLoading && (
                <Image
                  image={!isHighContrast ? Loading : LoadingHC}
                  width={60}
                  height={60}
                  className="mydata__loading"
                />
              )}
            </ul>
            {!isHistoryMoreLoading && hasMoreHistory && (
              <div className="mydata-personal-info__services-container__view-more">
                <Button
                  variant="outlined"
                  type="content"
                  title={t("PRIVACY_MYDATA_MANAGE_BTN_VIEWMORE")}
                  onClick={() => handleViewMoreList()}
                  className="mydata-personal-info__services-container__view-more__button"
                />
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};
