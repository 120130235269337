import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ServicesCarousel } from "../../../../../components/__common/Carousel/Carousel";
import { Svg, SVG_ICONS } from "../../../../../components/__common/Svg/Svg";
import { Typography } from "../../../../../components/__common/Typography/Typography";
import { LinkButton } from "../../../../../components/__common/_controls";
import { ServiceEntity } from "../../../../../types/services";
import { REQUEST_TYPE } from "../../../../../types/user";
import { UtilsKeyboardAccessibility } from "../../../../../utils/UtilsKeyboardAccessibility";
import "./Toast.scss";

interface ToastProps {
  selectedCount?: number;
  requestType: REQUEST_TYPE;
  services: ServiceEntity[];
  onClickAction?: (val: boolean) => void;
  onClickRemoveService: (serviceCode: ServiceEntity) => void;
  onClose?: () => void;
}

export const Toast: React.FC<ToastProps> = (props) => {
  const { t } = useTranslation();
  const {
    onClickAction = () => {},
    selectedCount = 0,
    requestType,
    onClickRemoveService,
    onClose,
  } = props;

  useEffect(() => {
    UtilsKeyboardAccessibility.addTabEventListener(
      "mydata-toast-close",
      "mydata-toast"
    );

    return () => {
      UtilsKeyboardAccessibility.removeTabEventListener(
        "mydata-toast-close",
        "mydata-toast"
      );
    };
  }, []);

  useEffect(() => {
    if (selectedCount > 0) {
      UtilsKeyboardAccessibility.setFocus("mydata-toast");
    }
  }, [selectedCount]);

  return (
    <div className="mydata-toast" id="mydata-toast" tabIndex={0}>
      <div className="mydata-toast__count">
        <Typography
          variant="body3"
          component="span"
          className="mydata-toast__count__text"
        >
          {t("PRIVACY_MYDATA_MANAGE_ACTION_BAR_SELECTED")}
        </Typography>
        <Typography variant="title2" className="mydata-toast__count__number">
          {("0" + selectedCount).slice(-2)}
        </Typography>
      </div>
      <ServicesCarousel
        services={props.services}
        onClickRemoveService={(sCode) => onClickRemoveService(sCode)}
      />
      <div className="mydata-toast__button">
        <LinkButton
          to="#"
          icon={
            requestType === REQUEST_TYPE.DOWNLOAD
              ? SVG_ICONS.MYDATA_DOWNLOAD_BTN
              : requestType === REQUEST_TYPE.DELETE
              ? SVG_ICONS.MYDATA_DELETE_BTN
              : SVG_ICONS.MYDATA_OPTOUT_BTN
          }
          title={
            requestType === REQUEST_TYPE.DOWNLOAD
              ? t("PRIVACY_MYDATA_MANAGE_ACTION_BAR_BTN_DOWNLOAD")
              : requestType === REQUEST_TYPE.DELETE
              ? t("PRIVACY_MYDATA_MANAGE_ACTION_BAR_BTN_DELETE")
              : t("PRIVACY_MYDATA_MANAGE_ACTION_BAR_BTN_OPTOUT")
          }
          iconPosition="left"
          underlined={false}
          onClick={(e: any) => {
            e.preventDefault();
            onClickAction(true);
          }}
          iconSize={24}
          textClassName="mydata-toast__button__text"
        />
      </div>
      <div className="mydata-toast__close-container">
        <button
          className="mydata-toast__close-container__icon"
          id="mydata-toast-close"
          onClick={onClose}
        >
          <Svg icon={SVG_ICONS.TOAST_CLOSE} width={32} height={32} />
        </button>
      </div>
    </div>
  );
};
